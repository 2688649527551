import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

const NotFoundPage = () => {
  return (
    <Layout>
      <div className="container mx-auto font-opensans mt-5 px-6">
        <h1>404: Not Found</h1>
        <p> Uh oh.</p>
        <p>
          The page you requested could not be found. Is there any chance you were looking for our latest posts?
        </p>
        <Link to="/blog"
          className="text-blue-600 underline font-semibold hover:text-blue-800">
            Check our latest articles</Link>
        <br />
        <br />
        You can also use the menu at the top to visualize the styles of window covering choices for your home. 
      </div>
    </Layout>
  )
}

export default NotFoundPage
